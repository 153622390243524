export const config = {
  headers: {
    apiKey: "dkdywe_w278",
    clientId: "1827373mf"
  },
  apiUrl: "https://geo.multifaceted.info/api",
  // apiUrl: "http://localhost:5445/api",
}

export const defaultCoords = {
  lat: 55.95,
  lng: -3.17
}